<template>
  <div>
    <appheader index="3"></appheader>
    <div class="list">
      <div class="list_title">
        <div class="goReturn" @click="goReturn">返回</div>
        <span class="lang">{{ $route.query.text }}</span>
      </div>
      <div class="search">
        <div class="searchChild">
          <div>
            <div class="account mg">
              <span class="title">用户账号：</span>
              <el-input placeholder="输入用户账号" :disabled="$route.query.text == '修改账号'" prefix-icon="el-icon-search" v-model="userCode">
              </el-input>
              <div class="initial">初始密码：88888888</div>
            </div>
            <div class="account mg">
              <span class="title">用户姓名：</span>
              <el-input placeholder="输入用户姓名" prefix-icon="el-icon-search" v-model="realName"> </el-input>
            </div>
            <div class="account mg">
              <span class="role">用户角色：</span>
              <el-select v-model="userType" clearable filterable value-key="value" :popper-append-to-body="false" placeholder="请选择角色">
                <el-option v-for="item in categoriesList" :key="item.value" :label="item.value" :value="item.value"> </el-option>
              </el-select>
            </div>
          </div>
          <el-button class="mg" @click="newName">确 定</el-button>
        </div>
        <div class="addStore">
          <div>添加门店</div>
          <div>
            已选择<span>{{ multipleSelection.length }}</span
            >家
          </div>
        </div>
        <div class="demo-input-suffix topMg">
          <div class=" mg">
            <el-input placeholder="输入总公司编码查询" prefix-icon="el-icon-search" v-model="companyCode"> </el-input>
          </div>
          <div class=" mg">
            <el-input placeholder="输入公司编码查询" prefix-icon="el-icon-search" v-model="parentCode"> </el-input>
          </div>
          <div class=" mg">
            <el-input placeholder="输入网点编码查询" prefix-icon="el-icon-search" v-model="orgCode"> </el-input>
          </div>
          <div class=" mg">
            <el-input placeholder="输入司名称查询" prefix-icon="el-icon-search" v-model="parentName"> </el-input>
          </div>
          <div class=" mg">
            <el-input placeholder="输入网点名称查询" prefix-icon="el-icon-search" v-model="orgName"> </el-input>
          </div>
          <el-button class="mg" @click="search">搜 索</el-button>
          <el-button class="mg" @click="reset">重 置</el-button>
        </div>
      </div>
      <div class="tab">
        <el-table
          :data="tableData"
          v-loading="loading"
          :row-key="getRowKeys"
          element-loading-text="正在加载数据"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0,.1)"
          ref="multipleTable"
          border
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" :reserve-selection="true" width="55"> </el-table-column>
          <el-table-column prop="companyCode" label="总公司编码"> </el-table-column>
          <el-table-column prop="parentCode" label="公司编码"> </el-table-column>
          <el-table-column prop="orgCode" label="网点编码"> </el-table-column>
          <el-table-column prop="orgName" label="网点名称"> </el-table-column>
          <el-table-column prop="parentName" label="公司名称"> </el-table-column>
          <!-- <el-table-column prop="type"
                           label="类型">
            <template slot-scope="scope">{{scope.row.type | type}}</template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          background
          :page-sizes="[20, 100, 500, 1000]"
          :page-size="pageSize"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import Appheader from '../moudel/Appheader.vue';
import bottom from '../bady_bottom/bottom';
export default {
  name: 'Newaccount',
  data() {
    return {
      orgName: '',
      orgCode: '',
      userCode: '',
      companyCode: '',
      parentCode: '',
      parentName: '',
      getRowKeys(row) {
        return row.id;
      },
      tableData: [],
      titleType: '',
      loading: false,
      categoriesList: [{ value: '老板' }, { value: '店长' }, { value: '展厅' }],
      pageNum: 1,
      pageSize: 20,
      total: 0,
      userType: '',
      realName: '',
      deptIdList: [],
      multipleSelection: [],
    };
  },
  components: {
    Appheader,
    bottom,
  },
  mounted() {
    if (this.$route.query.text == '新增账号') {
      this.getList();
    } else {
      // 回显详情
      this.detail();
      this.getDept();
    }
  },
  // 修改
  filters: {
    type(value) {
      var type;
      if (value == 'P') {
        type = '公司';
        return type;
      } else if (value == 'B') {
        type = '机构';
        return type;
      } else if (value == 'S') {
        type = '网点';
        return type;
      }
    },
  },
  methods: {
    //返回上一页
    goReturn() {
      this.$router.go(-1);
    },
    detail() {
      var url = `/system/user/detail?id=` + this.$route.query.ids;
      this.$axios({
        url: url,
        method: 'get',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.userCode = res.data.data.userCode;
          this.realName = res.data.data.realName;
          if (res.data.data.userType == '1') return (this.userType = '老板');
          else if (res.data.data.userType == '2') return (this.userType = '店长');
          else return (this.userType = '展厅');
        }
      });
    },
    getDept() {
      this.loading = true;
      var url = `/system/dept/list`;
      this.$axios({
        url: url,
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userId: this.$route.query.ids,
          function: 'update',
          orgName: this.orgName,
          orgCode: this.orgCode,
          companyCode: this.companyCode,
          parentCode: this.parentCode,
          parentName: this.parentName,
        }),
      }).then((res) => {
        this.tableData = [];
        if (res.data.code == 0) {
          res.data.rows.forEach((element) => {
            this.tableData.push(element);
          });
          this.$nextTick(() => {
            this.tableData.forEach((res, index) => {
              if (res.checked > 0) {
                this.$refs.multipleTable.toggleRowSelection(this.tableData[index], true);
              }
            });
          });
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let str = this.tableData.filter((item) => !this.multipleSelection.some((ele) => ele.id === item.id));
      if (this.deptIdList.length == 0) {
        this.multipleSelection.forEach((res) => {
          this.deptIdList.push(res.id);
        });
      } else {
        this.multipleSelection.forEach((res) => {
          this.deptIdList.push(res.id);
        });
        const res = new Map();
        this.deptIdList = this.deptIdList.filter((a) => !res.has(a) && res.set(a, 1));
        this.deptIdList.forEach((res) => {
          str.forEach((item) => {
            if (res == item.id) {
              this.deptIdList.splice(
                this.deptIdList.findIndex((rlay) => {
                  return rlay == res;
                }),
                1
              );
            }
          });
        });
      }
    },
    //点击新增
    newName() {
      let user = '';
      if (this.userType == '老板') {
        user = '1';
      } else if (this.userType == '店长') {
        user = '2';
      } else if (this.userType == '展厅') {
        user = '3';
      }
      if (this.$route.query.text == '新增账号') {
        var url = `/system/user/save`;
        this.$axios({
          url: url,
          method: 'post',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            userCode: this.userCode,
            realName: this.realName,
            userType: user,
            deptIdList: this.deptIdList,
          }),
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: '新增成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.$router.push({ path: '/accountmanagement' });
              },
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning',
              duration: 1500,
            });
          }
        });
      } else {
        var urlS = `/system/user/edit`;
        this.$axios({
          url: urlS,
          method: 'post',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            userCode: this.userCode,
            realName: this.realName,
            id: this.$route.query.ids,
            userType: user,
            deptIdList: this.deptIdList,
          }),
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.$router.push({ path: '/accountmanagement' });
              },
            });
          } else {
            this.$message({
              message: '修改失败！',
              type: 'warning',
              duration: 1500,
            });
          }
        });
      }
    },
    // 搜索
    search() {
      this.pageNum = 1;
      if (this.$route.query.text == '新增账号') {
        this.getList();
      } else {
        this.getDept();
      }
    },
    // 重置
    reset() {
      this.companyCode = null;
      this.parentCode = null;
      this.orgCode = null;
      this.parentName = null;
      this.orgName = null;
      this.pageNum = 1;
      if (this.$route.query.text == '新增账号') {
        this.getList();
      } else {
        this.getDept();
      }
    },
    getList() {
      this.tableData = [];
      this.loading = true;
      var url = `/system/dept/list`;
      this.$axios({
        url: url,
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          orgName: this.orgName,
          orgCode: this.orgCode,
          companyCode: this.companyCode,
          parentCode: this.parentCode,
          parentName: this.parentName,
          function: 'add',
        }),
      }).then((res) => {
        if (res.data.code == 0) {
          res.data.rows.forEach((element) => {
            this.tableData.push(element);
          });
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.$route.query.text == '新增账号') {
        this.getList();
      } else {
        this.getDept();
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (this.$route.query.text == '新增账号') {
        this.getList();
      } else {
        this.getDept();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.list {
  width: 1500px;
  margin: 0 auto;
  min-height: 550px;
}
.list_title {
  // display: flex;
  // align-items: center;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin-top: 28px;
  .goReturn {
    font-size: 14px;
    width: 60px;
    text-align: center;
    margin-right: 20px;
    cursor: pointer;
    color: #fff;
    height: 30px;
    line-height: 30px;
    background: #ec9368;
  }
  .lang {
    margin: 28px 0 10px 0;
    display: block;
  }
}
.search {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}
.searchChild {
  display: flex;
  .initial {
    font-size: 12px;
    color: #909399;
    margin-left: 20px;
  }
  .el-input {
    width: 250px;
  }
}
.addStore {
  display: flex;
  div:nth-child(2) {
    margin-left: 10px;
  }
  span {
    color: #ec9368;
    margin: 0 10px;
  }
}
.demo-input-suffix {
  display: flex;
  align-content: center;
  align-items: center;
}
.topMg {
  margin-top: 20px;
}
.account:last-child {
  margin-left: 20px;
}
.account {
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
  .el-select-dropdown__item {
    width: 228px;
    margin: 10px;
  }
  /deep/ .el-input--suffix .el-input__inner {
    width: 250px;
  }
  .title {
    // width: 120px;
  }
  .role {
    // width: 88.5px;
  }
}
.mg {
  margin-left: 20px;
}
.el-button:hover {
  border-radius: none;
  border: 1px solid #ec9368;
}
.el-button {
  width: 80px;
  padding: 0;
  color: #fff;
  background: #ec9368;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: none;
}
.tab {
  margin: 20px 0 0 0;
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  div {
    width: 100px;
    height: 40px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    background: #ec9368;
    margin-left: 20px;
    cursor: pointer;
  }
}
.page {
  text-align: center;
  margin: 40px 0;
}
/deep/ .el-table th,
.el-table tr {
  // background: #ec9368;
  // color: #fff;
}
.el-pagination.is-background .btn-next.disabled,
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev.disabled,
.el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .el-pager li.disabled {
  color: #c0c4cc !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ec9368 !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff !important;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:hover {
  color: #ec9368 !important;
}
/deep/ .el-pager li.active + li {
  border: none !important;
}
/deep/ .el-table_1_column_1 {
  background: #fff !important;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec9368 !important;
  border-color: #ec9368 !important;
}
/deep/ .el-checkbox__inner::after {
  height: 18px !important;
  left: 12px !important;
  top: 3px !important;
  width: 7px !important;
}
/deep/ .el-checkbox__inner {
  width: 32px !important;
  height: 32px !important;
}
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 15px !important;
}
/deep/ .el-checkbox__inner:hover {
  border-color: #ec9368 !important;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec9368 !important;
  border-color: #ec9368 !important;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec9368 !important;
  border-color: #ec9368 !important;
}
</style>
